import { useContext } from 'react';
import { GlobalContext } from '../../components/data/GlobalContext';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, EffectFade } from "swiper";


SwiperCore.use([Autoplay, Pagination, EffectFade]);


const MainSlider = () => {

    const dbData = useContext(GlobalContext);

    const mainSlideOptions = {
        slidesPerView: 1,
        loop: true,
        effect: "fade",
        pagination: {
            el: "#main-slider-pagination",
            type: "bullets",
            clickable: true
        },
        autoplay: {
            delay: 5000,
        },

    };

    const slides = (imgSlide) => {
        return (
            <SwiperSlide>
                <div
                    className="image-layer"
                    style={{ backgroundImage: `url("${imgSlide}")` }}
                ></div>
                <Container>
                    <Row className=" justify-content-start">
                        <Col xl={6} lg={12} className="text-left">
                            <h2>
                                {dbData.dbSlogan[2].slogan}
                            </h2>
                            <p>
                                {dbData.dbValues[0].description}
                            </p>
                            <Link to="/contact" className="thm-btn dynamic-radius text-capitalize">
                                free estimate
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </SwiperSlide>
        )
    }

    return (
        <section className="main-slider main-slider__two">
            <Swiper {...mainSlideOptions}>
                {slides(dbData.stock[5])}
                {slides(dbData.stock[7])}
                {slides(dbData.stock[3])}
                <div className="swiper-pagination" id="main-slider-pagination"></div>
            </Swiper>
        </section>
    );
};

export default MainSlider;
