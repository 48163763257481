import { useContext } from 'react';
import { GlobalContext } from '../../components/data/GlobalContext';


const imgUrl = require.context('../../assets/images', true);


function RedesContent(props) {
    const dbData = useContext(GlobalContext);
    return (
        <div className='contentRedes'>
            {
                dbData.dbSocialMedia.directorios && dbData.dbSocialMedia.directorios[0].name !== ' ' ?
                    dbData.dbSocialMedia.directorios.map((item, index) => {
                        return (
                            <div key={index}>
                                <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                    <img src={item.image} alt="Not found" width={'250px'} />
                                </a>
                            </div>
                        )
                    }) : null
            }
            {
                dbData.dbSocialMedia.redes && dbData.dbSocialMedia.redes[0].name !== ' ' ?
                    dbData.dbSocialMedia.redes.map((item, index) => {
                        return (
                            <div key={index}>
                                <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                    <img src={item.image} alt="Not Found" width={'250px'} />
                                </a>
                            </div>
                        )
                    }) : null
            }
        </div>
    );
}

export default RedesContent;