import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import { GiThermometerCold } from "react-icons/gi";



const CallToActionTwo = ({bgImages, title}) => {
    console.log('services',bgImages)
    return (
        <section className="call-to-action-two">
            <div
                className="call-to-action-two__bg"
                style={{ backgroundImage: `url("${bgImages}")` }}
            ></div>
            <Container className="container pt-80 pb-80">
                <GiThermometerCold className="azino-icon-charity call-to-action-two__icon"  />
                <Row className=" align-items-center">
                    <Col lg={7}>
                        <div className="block-title">
                            <h3>
                                {title}
                            </h3>
                        </div>
                    </Col>
                    <Col
                        lg={5}
                        className=" d-flex justify-content-start justify-content-lg-end"
                    >
                        <div className="btn-wrap">
                            <Link className="scroll-to-target thm-btn text-capitalize" to="/contact">
                                free estimate
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default CallToActionTwo;