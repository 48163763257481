

import { GiThermometerCold } from "react-icons/gi";



const IconTitle = ({ tagLine }) => {
    return (
        <div className="block-title">
            <p>
                <GiThermometerCold fontSize='50' color="#002E6B" />
                <span className="ms-3 text-capitalize">{tagLine}</span>
            </p>
        </div>
    );
};

export default IconTitle;