import React, { useContext, useState } from "react";
import { GlobalContext } from "../data/GlobalContext";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import NavsLink from "./NavsLink";


import * as FaIcons from 'react-icons/fa';


import flag1 from "../../assets/images/resources/flag-1-1.jpg";
// import logoDark from "../../assets/images/logo-dark.png";

const HeaderTwo = () => {
    const dbData = useContext(GlobalContext);

    const [hasMounted, setHasMounted] = useState(false);

    const openMenu = () => setHasMounted(!hasMounted);

    return (
        <header className="main-header" >
            <Container>
                <div className="inner-container" >
                    <Row>
                        <Col sm={12} md={12} lg={12} xl={3}>
                            <div className="logo-box">
                                <Link to="/">
                                    <img src={`${dbData.dbPrincipal.logo}`} width="250" alt="" />
                                </Link>
                                <span
                                    className="fa fa-bars mobile-nav__toggler"
                                    onClick={openMenu}
                                ></span>
                            </div>
                        </Col>
                        <Col
                            sm={12}
                            md={12}
                            lg={12}
                            xl={9}
                            className="d-none d-md-none d-lg-none d-xl-block"
                        // style={{position:'relative'}}
                        >
                            <div className="main-header__top">
                                <p>Welcome to {dbData.dbPrincipal.name}</p>
                                <div className="main-header__social">
                                    {
                                        dbData.dbSocialMedia.redes.map((item, index) => {
                                            const Social =
                                                FaIcons[
                                                item.icon
                                                ];
                                            return (
                                                <>
                                                    {
                                                        item.name !== ' ' ?
                                                            <a
                                                                key={index}
                                                                href={item.url}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <Social />
                                                            </a>
                                                            : null

                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <nav className="main-menu dynamic-radius">
                                <NavsLink />
                            </nav>
                            <div className="main-header__info">
                                <ul className="list-unstyled main-header__info-list">
                                    <li>
                                        <i className="azino-icon-email"></i>
                                        <a href={`mailto:${dbData.dbPrincipal.emails[0].email}`}>{dbData.dbPrincipal.emails[0].email}</a>
                                    </li>
                                    {/* multiple numero telefono */}
                                    {/* {
                                        dbData.dbPrincipal.phones.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <i className="azino-icon-telephone"></i>
                                                    <a href={`tel:${item.phone}`}>{item.phone}</a>
                                                </li>
                                            )
                                        })
                                    } */}
                                    <li>
                                        <i className="azino-icon-telephone"></i>
                                        <a href={`tel:${dbData.dbPrincipal.phones[0].phone}`}>{dbData.dbPrincipal.phones[0].phone}</a>
                                    </li>
                                    <li>
                                        <i className="azino-icon-clock"></i>
                                        <span>{dbData.dbPrincipal.workHours[0].hour}</span>
                                    </li>
                                </ul>
                                <div className="main-header__language">
                                    <Link className="thm-btn dynamic-radius text-capitalize" to="/contact">
                                        free estimate
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            {/* mobil */}
            <div className={hasMounted ? 'mobile-nav__wrapper expanded' : 'mobile-nav__wrapper'}>
                <div className="mobile-nav__overlay side-menu__toggler side-menu__block-overlay"></div>
                <div className="mobile-nav__content">
                    <span className="mobile-nav__close side-menu__toggler  side-menu__close-btn" onClick={openMenu}>
                        <i className="far fa-times" ></i>
                    </span>

                    <div className="logo-box">
                        <Link to="/">
                            <img src={`${dbData.dbPrincipal.logo}`} width="220" alt="" />
                        </Link>
                    </div>
                    <div className="mobile-nav__container">
                        <NavsLink />
                    </div>

                    <ul className="mobile-nav__contact list-unstyled">
                        {
                            dbData.dbPrincipal.emails.map((email, index) => {
                                return (
                                    <li key={index}>
                                        <i className="azino-icon-email"></i>
                                        <a href={`mailto:${email.email}`}>{email.email}</a>
                                    </li>
                                )
                            })
                        }
                        {
                            dbData.dbPrincipal.phones.map((phone, index) => {
                                return (
                                    <li key={index}>
                                        <i className="azino-icon-telephone"></i>
                                        <a href={`tel:${phone.phone}`}>{phone.phone}</a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </header>
    );
};

export default HeaderTwo;
